<template>
<div class="container">
    <div class="blogItems-title-container">
        <p class="blogItems-title">آخرین مطالب بلاگ</p>
        <hr>
    </div>
    <carousel :rtl='true' :per-page="4" :autoplay='true' :loop='true' :speed='900' :per-page-custom='[[320, 1], [1000, 4]]' :autoplay-timeout="3000">
        <slide  v-for="(news , index) in news" :key="index"  >
            <!-- {{ news._embedded['wp:featuredmedia'][0]['media_details']['sizes']['medium']['source_url'] }} -->
            
            <div class="blog-item" >
                <div class="image-container">
                    <!-- <img src="@/assets/blog.png" alt=""> -->
                    <img width="150px" height="150px" :src="news._embedded['wp:featuredmedia'][0]['media_details']['sizes']['medium']['source_url'] " alt="">
                </div>
                <div class="blog-text">
                    <p class="item-title">{{news.title.rendered}}</p>
                    <p class="item-discribe">{{$G2J(news.date)}}</p>
                </div>
            </div>
        </slide>
    </carousel>
</div>
</template>

<script>
import {
    Carousel,
    Slide
} from 'vue-carousel';
import Items from "../../library/BlogItems.js"
export default {
    name: 'BlogItems',
    components: {
        Carousel,
        Slide,
    },
    data() {
        return {
            BlogItems: Items,
            news:[]
        }
    },
      methods: {
        async getNews() {
            await fetch('http://blog.6.vistadev.ir/wp-json/wp/v2/posts?per_page=8&context=embed&page=1&_embed=true').then(response => response.json()).then(data => this.news=data);
           
        }
    },
    mounted() {
        this.getNews()
    },
}
</script>

<style scoped>
.slide {
    background: rgba(255, 255, 255, 0.04);
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(20px);
    border-radius: 5px;
    width: 305px;
    height: 91px;
    display: flex;
    justify-content: space-between;
}

hr {
    height: 1px;
    width: 250px;
    background-image: linear-gradient(90deg, transparent, white, transparent);
    border: 0;
}

.blogItems-title {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;

}

.blog-item {
    width: 244px;
    height: 280px;
    background: #151619;
}

.blog-text {
    padding: 10px 10px 40px 10px;
}

.item-title {
    font-weight: 500;
    font-size: 16px;
    color: #DBABA7;
}

.item-discribe {
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);

}

.blogItems-title-container {
    margin-bottom: 60px;
}
.image-container {
    display: flex;
    justify-content: center;
}
</style>
